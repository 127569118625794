<template>
  <div class="mt-4 settings-billing">

    <div class="corporate-property-tab">
      <hb-header fewer-actions :padding="false" align-top class="mt-n2">
        <template v-slot:left>
          <v-tabs
            v-model="activeTab"
            class="hb-text-light"
            background-color="#f9fafb"
            color="#101318"
            show-arrows
          >
            <v-tabs-slider color="#00848E"></v-tabs-slider>
            <v-tab
              v-for="menuOption in menuOptions"
              :key="menuOption.key"
              :href="'#tab-' + menuOption.key"
              class="text-capitalize"
              @click="tabChange(menuOption.key)"
            >
              {{ menuOption.label }}
            </v-tab>
          </v-tabs>
        </template>
      </hb-header>
    </div>


    <v-row v-if="isPropertyLevel">
      <v-col style="max-width: 350px;" class="pt-0">
        <v-autocomplete
          id="facility"
          rounded
          :items="facilityList"
          item-text="name"
          item-value="id"
          v-validate="'required'"
          v-model="property_id"
          data-vv-name="facility"
          data-vv-as="Property"
          outlined
          dense
          single-line
          background-color="white"
          label="Select Property"
          :error-messages="errors.collect('facility')"
          :hide-details="!errors.collect('facility').length"
        >
          <template v-slot:selection="data">
            <span class="truncate">{{ data.item.name }}</span>
          </template>
          <template v-slot:item="data">
            <span class="truncate">{{ data.item.name }}</span>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-divider v-if="isPropertyLevel" class="pb-6"></v-divider>
    <v-expansion-panels
      elevation="0"
      class="hb-expansion-panel"
      v-model="panel"
      multiple
      :disabled="(isPropertyLevel && !property_id) ? true : false"
    >
      <v-expansion-panel class="mb-4" key="transactional">
        <v-expansion-panel-header class="hb-default-font-size pt-3 pb-4 px-6">
          <v-col cols="12" class="pa-0 ma-0">
            <span class="text-h6">Transactional</span>
          </v-col>
        </v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content>
          <transactional
            :level="level"
            :facilityID.sync="property_id"
          >
          </transactional>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mb-2" key="reversals">
        <v-expansion-panel-header class="hb-default-font-size pt-3 pb-4 px-6">
          <v-col cols="12" class="pa-0 ma-0">
            <span class="text-h6">Reversals</span>
          </v-col>
        </v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content>
          <reversals
            :level="level"
            :facilityID.sync="property_id"
          >
          </reversals>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
  <!-- <div class="section-content">
    <div class="primary-section-content">
      <div>
        <status @resetStatus="errorClear($options.name)" v-if="(errorHas($options.name) || errors.has('offset') || errors.has('taxrate')) && errorMsg !== ''" :message="errorMsg" status="error"></status>
        <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
        
        <v-card outlined style="border-radius: 0px;">
          <v-card-text class="pl-3 py-0 pr-0 hide-overflow">
            <v-row class="mr-0">
              <v-col class="pa-0" cols="3">
                <div class="billing-label">
                  <div class="pa-4 pl-6">
                    <span class="font-weight-medium hb-default-font-size">Account Auto-Charge Offset</span>
                  </div>
                </div>
              </v-col>
              <v-col class= "py-0" cols="9">
                <div class="pt-4 pb-7 px-3">
                  <p class="hb-text-light hb-default-font-size">Enter number of days before invoice due date you want to process payments.</p>
                  <div style="display:inline-flex">
                    <v-text-field 
                      v-model="settings.invoiceChargeOffset"
                      v-validate="'required|integer|min:0|max:45'"
                      class="w-input"
                      style="width: 90px;"
                      id="offset"
                      name="offset"
                      :error="errors.has('offset')"
                    >
                     :error-messages="errors.first('offset')" -->
  <!-- </v-text-field>
                    <p class="mb-0 pt-2 ml-2">Days</p>
                  </div>

                </div>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          
            <v-row class="mr-0">
              <v-col class="pa-0" cols="3">
                <div class="billing-label">
                  <div class="pa-4 pl-6">
                    <span class="font-weight-medium hb-default-font-size">Enter tax rate</span>
                  </div>
                </div>
              </v-col>
              <v-col class= "py-0" cols="9">
                <div class="pt-4 pb-7 px-3">
                  <p class="hb-text-light mb-0 hb-default-font-size pb-4">Enter the sales tax rate for merchandise that requires tax. If you are a QuickBooks user, this rate can be overridden by the rate set up in that application.</p>
                  <div style="display:inline-flex">
                    <v-text-field 
                      v-model="settings.taxRate"
                      v-validate="'required|decimal:2|max:45'"
                      class="w-input"
                      style="width: 95px;"
                      placeholder="7.25"
                      id="taxrate"
                      name="taxrate"
                      :error="errors.has('taxrate')"
                    >
                    </v-text-field>
                    <p class="mb-0 pt-2 ml-2">%</p>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-divider></v-divider>
              </v-col>
            </v-row>         
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="py-3">
            <v-spacer></v-spacer>
            <a 
              class="mr-2"
              @click="cancel"
            >
              Cancel
            </a> 
            <hb-btn
              class="mr-2"
              color="primary"
              :disabled="isLoading($options.name)"
              @click="save"
              :loading="isLoading($options.name)"
            >
              Send
            </hb-btn>
          </v-card-actions>
        </v-card>
      </div>
  </div>
  </div> -->
</template>

<script type="text/babel">
import Transactional from "./Transactional/Index.vue";
import Reversals from "./Reversals/Index.vue";
import Status from "../includes/Messages.vue";
import api from "../../assets/api.js";
import { mapGetters } from "vuex";

export default {
  name: "BillingSettings",
  // props: ['level'],
  data() {
    return {
      level : "Corporate",
      activeTab: "tab-Corporate",
      menuOptions: [
        {
          key: "Corporate",
          label: "Corporate Settings",
        },
        {
          key: "Property",
          label: "property Settings",
        }
      ],
      panel: [],
      category: "billing",
      settings: {
        invoiceChargeType: "$",
        defaultDays: "",
        lockoutDays: "",
        auctionDays: "",
        invoiceSendDay: "",
        taxRate: "",
        invoiceChargeOffset: "",
      },
      lateTypes: ["$", "%"],
      errorMsg: "",
      property_id: null
    };
  },
  components: {
    Transactional,
    Reversals,
    Status,
  },
  created() {
    this.fetchData();
  },
  computed:  {
    ...mapGetters({
      facilityList: "propertiesStore/filtered",
      defaultProperty: "propertiesStore/defaultProperty"
    }),
    isPropertyLevel() {
      return this.level === 'Property' ? true : false;
    }
  },
  methods: {
    tabChange(tabName){
      if (tabName == 'Corporate'){
        this.property_id = this.property_id = this.defaultProperty ? this.defaultProperty.id : ""
        this.level = "Corporate"
        this.fetchData();
      }
      else{
        this.property_id = this.property_id = this.defaultProperty ? this.defaultProperty.id : ""
        this.level = "Property"
        this.panel = [];
      }
    },
    fetchData() {
      this.panel = [];
      api.get(this, api.SETTINGS_BILLING).then((r) => {
        this.settings = r.settings;
      });
    },
    save() {
      var save = {
        category: this.category,
        data: this.settings,
      };
      this.validate(this).then((status) => {
        if (!status) {
          this.errorMsg = "";
          if (this.errorHas(this.$options.name)) {
            this.errorMsg = this.errorGet(this.$options.name) + "<br>";
          }

          if (this.errors.has("offset")) {
            this.errorMsg += this.errors.first("offset") + "<br>";
          }

          if (this.errors.has("taxrate")) {
            this.errorMsg += this.errors.first("taxrate");
          }
          return;
        }
        api.post(this, api.SETTINGS, save).then((r) => {
          this.successSet(this.$options.name, "Billing settings Updated");
        });
      });
    },
    cancel() {
      this.$router.push({ path: "/settings" });
    },
  },
  watch: {
  },
};
</script>

<style lang="scss" scoped>
.settings-billing .v-expansion-panel--active:not(:first-child), .v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0px;
}
.billing-label {
  background-color: #f9fafb;
  height: 100%;
  min-height: 108px;
  width: 100%;
  color: #101318;
  display: flex;
}
</style>
